var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h1', [_vm._v("LAPORAN KEGIATAN OPERASIONAL ( JASA KEPELABUHANAN ) + Standard Kinerja Kepelabuhanan")]), _c('hr')]), _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-card', {
    attrs: {
      "body-class": "p-0"
    }
  }, [_c('div', {
    staticClass: "p-2",
    attrs: {
      "align-h": "between",
      "align-v": "center"
    }
  }, [_c('br'), _c('b-img', {
    staticStyle: {
      "position": "absolute",
      "right": "0",
      "opacity": "20%",
      "top": "0"
    },
    attrs: {
      "fluid": "",
      "src": _vm.imgUrl,
      "alt": "Reporting River"
    }
  }), _c('ReportingRiver')], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }