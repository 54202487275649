<template>
  <b-row>
    <b-col cols="12">
      <h1>LAPORAN KEGIATAN OPERASIONAL ( JASA KEPELABUHANAN ) + Standard Kinerja Kepelabuhanan</h1>
      <hr />
    </b-col>
    <b-col md="12" cols="12">
      <b-card body-class="p-0">
        <div class="p-2" align-h="between" align-v="center">
          <!-- <h2>TERMINAL PT. SARANA ABADI LESTARI</h2> -->
          <br />
          <b-img
            fluid
            :src="imgUrl"
            alt="Reporting River"
            style="position: absolute; right: 0; opacity: 20%; top: 0"
          />
          <ReportingRiver />
        </div>
      </b-card>
    </b-col>
    <!-- img -->
  </b-row>
</template>

<script>
import { BRow, BCol, BCard, BCardFooter, BImg, BCardText } from 'bootstrap-vue'
import ReportingRiver from './ReportingRiver.vue'

import store from '@/store/index'
export default {
  components: {
    ReportingRiver,
    BCard,
    BCardFooter,
    BCardText,
    BRow,
    BImg,
    BCol
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/register-v2.svg')
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.downImg
      }
      return this.downImg
    }
  }
}
</script>

<style></style>
