<template>
  <validation-observer ref="formRules" tag="form">
    <b-form @submit.prevent="generateExcel">
      <h6>maksimal 1 bulan dari waktu sandar yang ditentukan</h6>
      <hr />
      <b-row>
        <!-- submit and reset -->

        <!-- <b-col> {{ myDataToExport() }} </b-col> -->

        <b-col cols="12">
          <b-form-group
            :label="`PEMILIK (${
              option.lock_group_account
                ? `you don't have permission to change`
                : `You have permission to change`
            })`"
            label-for="vi-by_agent"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="PEMILIK"
              vid="vi-by_agent"
              rules="required"
            >
              <v-select
                id="vi-by_agent"
                v-model="form.by_agent"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="option.by_agents"
                :reduce="(option) => option.value"
                :disabled="option.lock_group_account"
                :selectable="(option) => !option.value.includes('null')"
                label="text"
                item-text="text"
                item-value="value"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Start Date ( Waktu Sandar )"
            label-for="lf_start_date"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Start Date ( Waktu Sandar )"
              vid="lf_start_date"
              rules="required"
            >
              <flat-pickr
                id="lf_start_date"
                v-model="form.start_date"
                class="form-control"
                static="true"
                placeholder="Waktu Sandar"
                :state="errors.length > 0 ? false : null"
                :config="dpconfig"
                @on-change="onStartChange"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="End Date ( Waktu Sandar )"
            label-for="lf_end_date"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="End Date ( Waktu Sandar )"
              vid="lf_end_date"
              rules="required"
            >
              <flat-pickr
                id="lf_end_date"
                v-model="form.end_date"
                class="form-control"
                static="true"
                placeholder="Waktu Sandar"
                :state="errors.length > 0 ? false : null"
                :config="configs.end"
                @on-change="onEndChange"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <br />
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            <feather-icon icon="CheckCircleIcon" class="mr-25" />
            Generate Excel File
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            @click="resetForm"
          >
            <feather-icon icon="XCircleIcon" class="mr-25" />
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  BCol,
  BCard,
  BCardFooter,
  BCardText
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VSelect from 'vue-select'
import moment from 'moment'
import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import flatPickr from 'vue-flatpickr-component'
import useJwt from '@/auth/jwt/useJwt'
import { v4 as uuidv4, NIL as uuidnil } from 'uuid'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import { getUserData } from '@/auth/utils'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    Indonesian,
    flatPickr,
    BCard,
    BCardFooter,
    BCardText,
    VSelect,
    FeatherIcon
  },
  directives: {
    Ripple
  },
  watch: {
    'form.by_agent': function () {
      const agent = this.form.by_agent
      // if (agent === 'SLB') {
      //   this.flag_agent = false
      //   // this.form.capacity_truck = null
      // } else {
      //   this.form.jenis_truck = null
      //   this.flag_agent = true
      // }
      this.API_SAL = this.MAP_API[agent]
    }
  },
  data() {
    return {
      // component_vi_segmen: 1,
      dpconfig: {
        // default
        wrap: true,
        enableTime: false,
        time_24hr: false,
        // altInput: true,
        // dateFormat: 'Y-m-d H:i',
        altInput: true,
        altFormat: 'j F Y',
        // altFormat: 'j F Y - h:m K',
        dateFormat: 'Y-m-d',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        defaultDate: null
      },
      dpconfigRevisi: {
        wrap: true,
        enableTime: false,
        time_24hr: false,
        altInput: true,
        altFormat: 'j F Y',
        // altFormat: 'j F Y - h:m K',
        dateFormat: 'Y-m-d',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        defaultDate: null
      },
      configs: {
        end: {
          wrap: true,
          enableTime: false,
          time_24hr: false,
          altInput: true,
          altFormat: 'j F Y',
          dateFormat: 'Y-m-d',
          locale: Indonesian,
          minDate: null,
          maxDate: null
        }
      },
      // flag_agent: true,
      form: {
        by_agent: null,
        start_date: null,
        end_date: null
        // capacity_truck: 'ALL',
        // jenis_truck: 'ALL',
        // segmen: null
      },
      MAP_API: {
        TEM: 'vw_monitor_jetty/reporting',
        PATRA: 'vw_monitor_jetty/reporting',
        PIL: 'vw_monitor_jetty/reporting',
        SLB: 'vw_monitor_jetty/reporting',
        'Hulu Migas': 'vw_monitor_jetty/reporting',
        ALL: 'vw_monitor_jetty/reporting',
        TBBM: 'vw_monitor_jetty/reporting',
        'JETTY 3A': 'vw_monitor_jetty/reporting'
      },
      API_SAL: 'vw_monitor_jetty/reporting',
      BACKEND_URI_SAL: process.env.VUE_APP_BACKEND_URL,

      option: {
        lock_group_account: true,
        by_agents: [
          { text: 'JETTY 3A', value: 'JETTY 3A' },
          {
            text: 'TBBM (TEM,PATRA, dan PIL)',
            value: 'TBBM'
          },
          { text: 'TEM', value: 'TEM' },
          { text: 'PATRA', value: 'PATRA' },
          { text: 'PIL', value: 'PIL' },
          { text: 'Hulu Migas', value: 'Hulu Migas' },
          { text: 'ALL', value: 'ALL' }
        ]
        // capacity_trucks: [
        //   { text: 'Kapasitas Truck', value: 'null' },
        //   { text: '5.000 Liter', value: '5000' },
        //   { text: '10.000 Liter', value: '10000' },
        //   { text: '16.000 Liter', value: '16000' },
        //   { text: '20.000 Liter', value: '20000' },
        //   { text: 'ALL', value: 'ALL' }
        // ],
        // jenis_trucks: [
        //   { text: 'Jenis Truck', value: 'null' },
        //   { text: 'TRUCK', value: 'TRUCK' },
        //   { text: 'LONGBED', value: 'LONGBED' },
        //   { text: 'TRAILER', value: 'TRAILER' },
        //   { text: 'MOBIL BOX', value: 'MOBIL BOX' },
        //   { text: 'MOBIL', value: 'MOBIL' },
        //   { text: 'BIS', value: 'BIS' },
        //   { text: 'ALL', value: 'ALL' }
        // ],
        // segmens: [
        //   { text: 'ELNUSA LMP', value: 'ELNUSA LMP' },
        //   { text: 'MI (LMP)', value: 'MI (LMP)' },
        //   { text: 'DAS (Cementing)', value: 'DAS (Cementing)' },
        //   { text: 'Facility (SLB)', value: 'Facility (SLB)' },
        //   { text: 'ELNUSA (Cementing)', value: 'ELNUSA (Cementing)' },
        //   { text: 'SAL', value: 'SAL' }
        // ]
      }
    }
  },
  created() {
    // const userData = getUserData()
    // const group = userData.group?.name ?? null
    if (this.$can('manage', 'all') || this.$can('SAL', '')) {
      this.option.lock_group_account = false
    } else {
      if (this.$can('TBBM', '')) {
        this.option.lock_group_account = true
        this.form.by_agent = 'TBBM'
      }
      if (this.$can('TBBM JETTY 3A', '')) {
          this.form.by_agent = 'JETTY 3A'
        }
      if (this.$can('Hulu Migas', '')) {
        this.option.lock_group_account = true
        this.form.by_agent = 'Hulu Migas'
      }
    }

    // segmen change
    // if (!this.$can('manage', 'all')) {
    //   const segmens = []
    //   this.$formSegmenSlb.forEach((value) => {
    //     if (this.$can(value.key, '')) {
    //       segmens.push({
    //         text: value.key,
    //         value: value.key
    //       }) /* elnusa cementing membawahi elnusa lmp (can create and approve) */
    //       if (value.key === 'ELNUSA (Cementing)') {
    //         segmens.push({ text: 'ELNUSA LMP', value: 'ELNUSA LMP' })
    //       }
    //     }
    //   })
    //   this.form.segmen = segmens[0].value
    //   this.option.segmens = segmens
    // }
  },
  methods: {
    myDataToExport() {
      let label = null
      if (this.$can('SAL', '')) {
        return (label = null)
      }
      if (this.$can('TBBM', '')) {
        return (label = 'TBBM')
      }
      if (this.$can('Hulu Migas', '')) {
        return (label = 'Hulu Migas')
      }
      if (this.$can('approvement', '')) {
        return (label = 'TBBM')
      }
      if (this.$can('manage', 'special')) {
        return (label = 'TBBM')
      }
    },
    onStartChange(selectedDates, dateStr, instance) {
      moment.addRealMonth = function addRealMonth(d) {
        var fm = moment(d).add(1, 'M')
        var fmEnd = moment(fm).endOf('month')
        return d.date() != fm.date() && fm.isSame(fmEnd.format('YYYY-MM-DD'))
          ? fm.add(1, 'd')
          : fm
      }
      const end = moment.addRealMonth(moment(dateStr)).subtract(1, 'days')
      this.configs.end.minDate = dateStr
      this.configs.end.maxDate = end.format('YYYY-MM-DD')
    },
    onEndChange(selectedDates, dateStr, instance) {
      // 2022-08-22T13:34:00.000Z
      // this.dpconfig.maxDate = dateStr
    },
    // selectAllSegmen(flag) {
    //   if (flag === true) {
    //     /* selectAll */
    //     const segmens = []
    //     this.option.segmens.forEach((element) => {
    //       segmens.push(element.value)
    //     })
    //     this.form.segmen = segmens
    //   } else {
    //     /* deSelectAll */
    //     this.form.segmen = null
    //   }
    // },
    resetForm() {
      // this.form.by_agent = null
      // this.form.capacity_truck = null
      this.form.jenis_truck = null
      this.form.start_date = null
      this.form.clock_out = null
    },
    generateExcel() {
      const payload = this.form
      console.log(payload)
      // this.$swal({
      //   title: 'Coming Soon!',
      //   text: '-',
      //   icon: 'info',
      //   timer: 1500,
      //   showCancelButton: false,
      //   customClass: {
      //     confirmButton: 'btn btn-success'
      //   },
      //   buttonsStyling: false
      // })

      return new Promise((resolve, reject) => {
        this.$refs.formRules.validate().then((success) => {
          if (success) {
            this.requestGet()
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    requestGet() {
      useJwt.http
        .get(this.API_SAL, { params: this.form })
        .then((response) => {
          const uri = `${this.BACKEND_URI_SAL}/${response.data.link_file_excel}`
          console.log(uri)
          window.open(uri)
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style></style>
